<template>
  <div class="wrapper">
    <main class="main">
      <transition
        name="fade"
        mode="out-in"
      >
        <router-view />
      </transition>
    </main>

    <el-backtop target=".wrapper">
      <div class="backtop-content">
        <svg-icon icon-class="chevron-up-solid" />
      </div>
    </el-backtop>
  </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import { registerPinBoardModuleContext, PIN_BOARD_MODULE_NAMESPACE } from '@/modules/pinBoard'
import { mapActions } from 'vuex'

export default defineComponent({
  name: 'Pinboard',
  setup () {
    registerPinBoardModuleContext()
  },
  mounted () {
    this.setCurrentAskingModuleBelongsTo(PIN_BOARD_MODULE_NAMESPACE)
  },
  methods: {
    ...mapActions('dataSource', ['setCurrentAskingModuleBelongsTo'])
  }
})
</script>

<style lang="scss" scoped>

.main {
  min-height: calc(100vh - #{$header-height});
  padding: 32px 24px 64px;
}

.backtop-content {
  color: #2ad2e2;
}
</style>
